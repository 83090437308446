import store from '@/store'

export default [
  {
    path: '/',
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/pages/HomePage.vue'),
        props: route => ({ mewsReady: route.query.mewsReady === 'true' }),
        meta: {
          title: 'Welcome to Section L',
        },
      },
      {
        path: 'scan/:resource?',
        name: 'Scan',
        component: () => import('@/pages/ScanPage.vue'),
        props: route =>
          route.params.resource && { resource: route.params.resource },
        meta: {
          title: 'Scan QR Code',
        },
      },
    ],
  },
  {
    path: '/logout',
    name: 'Logout',
    component: {
      name: 'Logout',
      template: '<div />',
    },
    meta: {
      title: 'Logout',
      async beforeResolve(to, from, next) {
        try {
          await store.dispatch('auth/logOut')
          const authRequiredOnPreviousRoute = from.matched.some(
            route => route.meta.authRequired
          )
          // Navigate back to previous page, or home as a fallback
          next(authRequiredOnPreviousRoute ? { name: 'Home' } : { ...from })
        } catch (e) {
          console.error(e)
        }
      },
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/LoginPage.vue'),
    meta: {
      title: 'Login',
      beforeResolve(to, from, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'Home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/checkin',
    name: 'Checkin',
    children: [
      {
        path: '',
        name: 'CheckinStart',
        component: () => import('@/pages/checkin/CheckinStart.vue'),
        meta: {
          skipIfMobile: true,
          async beforeResolve(routeTo, _routeFrom, next) {
            if (routeTo.query.mewsReady === 'true') {
              next({ name: 'CheckinCode' })
            } else {
              next()
            }
          }
        },
      },
      {
        path: 'code',
        name: 'CheckinCode',
        props: route => ({ mewsReady: route.query.mewsReady === 'true' }),
        component: () => import('@/pages/checkin/CheckinCode.vue'),
      },
      {
        path: 'booking',
        name: 'ReservationConfirmation',
        props: route => ({ mewsReady: route.query.mewsReady === 'true' }),
        component: () => import('@/pages/checkin/ReservationConfirmation.vue'),
        meta: {
          redirectIfNoBooking: { name: 'Home' },
        },
      },
      {
        path: 'minor',
        name: 'MinorGuestInfo',
        component: () => import('@/pages/checkin/MinorGuestInfo.vue'),
      },
      {
        path: 'minor_id',
        name: 'MinorIdInfo',
        component: () => import('@/pages/checkin/MinorIdInfo.vue'),
      },
      {
        path: 'minor_photo',
        name: 'MinorMugshotCapture',
        component: () => import('@/pages/checkin/MinorMugshotCapture.vue'),
      },
      {
        path: 'minor_pre_checkin_end',
        name: 'MinorPreCheckinEnd',
        component: () => import('@/pages/checkin/MinorPreCheckinEnd.vue'),
      },
      {
        path: 'email',
        name: 'Email',
        component: () => import('@/pages/checkin/Email.vue'),
        props: route => ({
          email: route.query.email,
          primary: route.query.primary,
          inviteUuid: route.query.uuid,
        }),
      },
      {
        path: 'guest_info',
        name: 'GuestInfo',
        component: () => import('@/pages/checkin/GuestInfo.vue'),
      },
      {
        path: 'contact',
        name: 'ContactInfo',
        component: () => import('@/pages/checkin/ContactInfo.vue'),
      },
      {
        path: 'travel',
        name: 'TravelInfo',
        component: () => import('@/pages/checkin/TravelInfo.vue'),
      },
      {
        path: 'id',
        name: 'IdInfo',
        component: () => import('@/pages/checkin/IdInfo.vue'),
      },
      {
        path: 'photo',
        name: 'MugshotCapture',
        component: () => import('@/pages/checkin/MugshotCapture.vue'),
      },
      {
        path: 'optional_photo',
        name: 'MugshotCaptureSkippable',
        component: () => import('@/pages/checkin/MugshotCaptureSkippable.vue'),
      },
      {
        path: 'other_guests',
        name: 'OtherGuests',
        component: () => import('@/pages/checkin/OtherGuests.vue'),
      },
      {
        path: 'almost_done',
        name: 'AlmostDone',
        component: () => import('@/pages/checkin/AlmostDone.vue'),
      },
      {
        path: 'pre_checkin_end',
        name: 'PreCheckinEnd',
        component: () => import('@/pages/checkin/PreCheckinEnd.vue'),
      },
      {
        path: 'agreement',
        name: 'CheckinAgreement',
        component: () => import('@/pages/checkin/CheckinAgreement.vue'),
      },
      {
        path: 'end',
        name: 'CheckinEnd',
        component: () => import('@/pages/checkin/CheckinEnd.vue'),
      },
    ],
  },
  {
    path: '/checkout',
    name: 'Checkout',
    children: [
      {
        path: '',
        name: 'CheckoutStart',
        component: () => import('@/pages/checkout/CheckoutStart.vue'),
      },
      {
        path: 'code',
        name: 'CheckoutEnd',
        component: () => import('@/pages/checkout/CheckoutEnd.vue'),
      },
    ],
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/pages/SettingsPage.vue'),
    meta: {
      authRequired: true,
      title: 'Settings',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    props: (route: any) => ({
      backEnabled: route.params.backEnabled === 'true',
    }),
    children: [],
    redirect: { name: 'Home' },
  },
]
