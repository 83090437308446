<template>
  <div v-if="visible" class="contents">
    <div
      class="shadowStyles fixed left-1/2 top-1/2 flex w-11/12 -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-between gap-y-3 bg-white px-[40px] py-[64px] sm:max-w-[952px] sm:gap-y-8 lg:max-w-[952px]"
      :style="`z-index: ${zIndex};`"
      v-bind="$attrs"
    >
      <div
        class="text-medium absolute right-8 top-8 cursor-pointer leading-none text-black md:right-12 md:text-xl"
        @click="close"
      >
        ✕
      </div>
      <slot />
    </div>
    <overlay
      class="cursor-pointer"
      :style="`z-index: ${zIndex - 1};`"
      @click="close"
    />
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import Overlay from '@/components/Overlay.vue'
import { ref } from 'vue'

withDefaults(defineProps<{ zIndex?: number }>(), { zIndex: 100 })

const visible = ref(false)

const close = () => {
  visible.value = false
  emit('close')
}

const show = () => {
  visible.value = true
}

const emit = defineEmits<{
  (e: 'close'): void
}>()
defineExpose({ close, show })
</script>

<style>
.shadowStyles {
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);
}
</style>
