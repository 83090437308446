import { email, date } from '@/utils/i18nValidators'
import { ValidationArgs } from '@vuelidate/core'

export const VALIDATORS: { [key: string]: ValidationArgs } = {
  email: { email },
  date: { date },
  text: {},
  select: {},
}

export default function inputValidationsPerType(
  inputType?: keyof typeof VALIDATORS
): ValidationArgs {
  if (!inputType) return {}
  return VALIDATORS[inputType]
}
