import { Checkin, Guest } from '@/types/api/types'
import Repository from './repository'

export type PatchCheckinGuestArgs = {
  checkinId: number
  guest: Partial<Guest> & { id: number } // id is required, all other properties are optional
}

export type ReassignGuestArgs = {
  checkinId: number
  email: string
  dateOfBirth?: string
}

export default {
  postCheckin({
    email,
    bookingNo,
    propCode,
    primary,
    inviteUuid,
    dateOfBirth,
  }: {
    email: string
    bookingNo: string
    propCode: string
    primary: 'true' | 'false'
    inviteUuid?: string
    dateOfBirth?: string
  }) {
    return Repository.post<Checkin>(`/bookings/${bookingNo}/checkins`, {
      email,
      propCode,
      primary,
      inviteUuid,
      dateOfBirth,
    })
  },
  patchCheckin({ checkin, propCode }) {
    return Repository.patch<Checkin>(`/checkins/${checkin.id}`, {
      checkin,
      propCode,
    })
  },
  patchGuest({
    checkinId,
    guest,
    propCode,
  }: PatchCheckinGuestArgs & { propCode: string }) {
    return Repository.patch<Checkin>(
      `/checkins/${checkinId}/guests/${guest.id}`,
      {
        guest,
        propCode,
      }
    )
  },
  reassignGuest({ checkinId, email, dateOfBirth }: ReassignGuestArgs) {
    return Repository.post<Checkin>(`/checkins/${checkinId}/reassign_guest`, {
      email,
      dateOfBirth,
    })
  },
}
