<template>
  <modal ref="emailReusedModal" class="h-11/12 overflow-y-hidden">
    <div class="flex flex-col items-center justify-center gap-8">
      <p class="text-center">
        {{ $t('alerts.errors.emailReused.message') }}
      </p>
      <img
        src="@/assets/illustrations/sumo.png"
        alt="Sumo"
        class="object-contain"
      />
      <base-button @click="close">
        {{ $t('alerts.errors.emailReused.buttonText') }}
      </base-button>
    </div>
  </modal>
</template>

<script lang="ts">
import { onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'

import Modal from '@/components/modals/Modal.vue'
import { key } from '@/store'

export default {
  components: { Modal },

  setup() {
    const store = useStore(key)
    const emailReusedModal = ref(null)

    const customAlert = computed(() => store.getters.customAlert)

    const show = () => {
      emailReusedModal?.value?.show()
    }

    const close = async () => {
      emailReusedModal?.value?.close()
      await store.dispatch('resetCustomAlert')
    }

    onMounted(() => {
      if (customAlert.value.errorCode === 'EmailReusedError') show()
    })

    return {
      emailReusedModal,
      show,
      close,
    }
  },
}
</script>
