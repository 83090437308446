<template>
  <div
    class="flex w-full flex-col justify-center bg-powder px-4 py-2 md:px-16 md:py-10"
  >
    <div class="flex w-full items-center justify-between">
      <logo
        class="w-36 cursor-pointer md:w-48 lg:w-56"
        @click="confirmGoHome"
        @dblclick="openSettings"
      />
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import isMobileDetector from '@/utils/isMobile'
import scopedMsgs from '@/utils/i18nScopedMsgs'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import Logo from '@/components/Logo.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { key } from '@/store'

export default {
  components: { LocaleSwitcher, Logo },

  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore(key)
    const { t } = useI18n()

    const isMobile = computed(isMobileDetector)

    const openSettings = () => {
      if (!isMobile.value && route.name === 'Home')
        router.push({ name: 'Login', query: { redirectFrom: '/settings' } })
    }

    const confirmGoHome = () => {
      if (route.name !== 'Home') {
        store.dispatch('setAlert', {
          title: t('alerts.warnings.cancelToHome'),
          buttonText: t('alerts.info.backHome'),
          buttonAction: () => router.push({ name: 'Home' }),
        })
      }
    }

    return {
      confirmGoHome,
      isMobile,
      openSettings,
      t: scopedMsgs('alerts'),
    }
  },
}
</script>
