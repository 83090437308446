import { onBeforeUnmount, onMounted } from 'vue'

export default function useKeyboardInput(keyboardInput, event = 'keyup') {
  const addEventListener = () => {
    window.addEventListener(event, keyboardInput)
  }

  const removeEventListener = () => {
    window.removeEventListener(event, keyboardInput)
  }

  onMounted(() => {
    addEventListener()
  })

  onBeforeUnmount(() => {
    removeEventListener()
  })

  return { addEventListener, removeEventListener }
}
