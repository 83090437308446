import * as Sentry from '@sentry/vue'
import { config } from './constants'

export default function sentry(app, router) {
  Sentry.init({
    app,
    dsn: config.sentry.dsn,
    release: config.appVersion,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', /https?:\/\/.+\.section-l\.co/, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
