<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="isMobile ? 8 : 12"
    :height="isMobile ? 14 : 20"
    viewBox="0 0 9 14"
    fill="none"
  >
    <path
      d="M1.5 13L7.5 7L1.5 1"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup lang="ts">
import isMobileDetector from '@/utils/isMobile'
import { computed } from 'vue'
const isMobile = computed(isMobileDetector)
</script>
