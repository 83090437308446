const convertBlobToDataUrl = blob =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      function () {
        resolve(reader.result?.toString() || '')
      },
      false
    )
    try {
      reader.readAsDataURL(blob)
    } catch (e) {
      reject(e)
    }
  })

export default async function getBase64ImageFromURL(imageUrl) {
  const res = await fetch(imageUrl)
  const blob = await res.blob()
  if (blob.size === 0) return ''
  return convertBlobToDataUrl(blob)
}
