<template>
  <div>
    <squishable-thingy>
      <div
        :class="styles"
        class="mr-0 flex flex-row items-center gap-2 md:mr-8"
        @click="showSelector = true"
      >
        <icon-globe></icon-globe>
        <p
          class="cursor-pointer !border-b-0 !p-0 text-sm !font-medium text-black md:text-lg"
          data-cy="languageSwitcher"
        >
          {{ language.name }}
        </p>
      </div>
    </squishable-thingy>
    <ul
      v-if="showSelector"
      v-click-outside="() => (showSelector = false)"
      class="pointer-events-none absolute right-14 top-3 z-20 grid w-28 gap-2 border-2 border-black bg-powder p-3 shadow-lg md:right-[132px] md:top-[21px] md:w-32 md:p-4"
    >
      <li
        v-for="option in languageOptions"
        :key="option.locale"
        class="pointer-events-auto hover:cursor-pointer"
        :data-cy="`switchToLanguage${option.name}`"
        @click="changeLocale(option.locale)"
      >
        <squishable-thingy>
          <span
            class="border-2 border-transparent text-base font-medium md:text-lg md:font-medium"
            :class="{ 'border-b-black': option.locale === locale }"
          >
            {{ option.name }}
          </span>
        </squishable-thingy>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { directive as vClickOutside } from 'click-outside-vue3'
import { computed, onMounted, ref } from 'vue'
import getSavedState from '@/utils/getSavedState'
import { useI18n } from 'vue-i18n'
import { config } from '@/constants'
import saveStateToStorage from '@/utils/saveStateToStorage'
import SquishableThingy from './SquishableThingy.vue'
import IconGlobe from '@/components/icons/IconGlobe.vue'

const props = defineProps<{
  transparent: boolean
}>()

const { locale } = useI18n({ useScope: 'global' })

const showSelector = ref(false)

const languageOptions = [
  { locale: 'en', name: 'English' },
  { locale: 'ja', name: '日本語' },
  { locale: 'zh', name: '中文' },
]

const language = computed(() => {
  switch (locale.value) {
    case 'ja':
      return { locale: 'ja', name: '日本語' }

    case 'zh':
      return { locale: 'zh', name: '中文' }

    default:
      return { locale: 'en', name: 'English' }
  }
})

const changeLocale = chosenLocale => {
  if (!config.supportedLocales.includes(chosenLocale)) {
    return Promise.reject(new Error('Locale not supported'))
  }

  locale.value = chosenLocale
  saveStateToStorage<string>('locale', chosenLocale)
  showSelector.value = false
}

const styles = props.transparent
  ? 'text-bold font-2xl'
  : 'bg-lightgray p-[10px] rounded-[8px] flex items-center justify-center'

onMounted(() => {
  const storedLocale = getSavedState('locale')
  if (storedLocale) changeLocale(storedLocale)
})
</script>
