<template>
  <div
    :class="{
      'w-max transform transition-transform': !disabled,
      'scale-90': shallow && squished && !disabled,
      'scale-75': !shallow && squished && !disabled,
    }"
    @touchstart="squish"
    @touchend="unsquish"
    @touchcancel="unsquish"
    @mousedown="squish"
    @mouseup="unsquish"
    @mouseout="unsquish"
  >
    <slot />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'

defineProps<{ shallow?: boolean; disabled?: boolean }>()

const squished = ref<boolean>(false)
const squish = () => {
  squished.value = true
}
const unsquish = () => {
  squished.value = false
}
</script>
