<template>
  <base-label
    :class="labelClass"
    class="mb-4 w-full text-sm font-semibold md:mb-6 md:text-base md:font-medium"
  >
    {{ label }}
    <span v-if="props.showAsterisk" class="text-red-600">*</span>
    <multi-select
      :value="modelValue"
      :open-direction="openUpwards ? 'top' : 'bottom'"
      class="!h-[50px] !border-0 font-normal focus:!border-[1px] focus:!border-black focus:!outline-none md:font-medium"
      :class="{ '!border-[1px] !border-lightgray': borderAlwaysOn }"
      :options="i18nSelectOptions"
      value-prop="code"
      label="label"
      track-by="label"
      :placeholder="
        !!v$.value.$errors.length ? v$.value.$errors[0]?.$message : placeholder
      "
      :autocomplete="inputType"
      :disabled="disabled"
      :classes="{
        container: `multiselect ${
          !!v$.value.$errors.length ? '!border-[1px] error-border' : ''
        }`,
        placeholder: `multiselect-placeholder ${
          !!v$.value.$errors.length ? 'error-text' : ''
        }`,
      }"
      searchable
      @change="handleCustomInput"
    />
  </base-label>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import useValidations, { ModelValue } from '@/utils/setup/useValidations'
import countries from '@/utils/countries'
import i18n from '@/i18n'

interface LabelAndCode {
  label: string
  code?: string
}

const props = withDefaults(
  defineProps<{
    modelValue: ModelValue
    required: boolean
    autocomplete: boolean
    placeholder: string
    regionsCountry?: string
    disabled?: boolean
    label?: string
    labelClass?: string
    borderAlwaysOn?: boolean
    openUpwards?: boolean
    showAsterisk?: boolean
  }>(),
  {
    modelValue: () => null,
    borderAlwaysOn: false,
    openUpwards: false,
    showAsterisk: false,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: ModelValue): void
  (e: 'change', value: ModelValue): void
}>()

const emitChange = (value: ModelValue) => {
  emit('change', value)
}

const { handleCustomInput, v$ } = useValidations(props, emit, emitChange)

const { t, locale } = i18n.global || i18n

const i18nSelectOptions = computed(() => {
  if (props.regionsCountry) {
    const countryData = countries.find(
      country => country.code === props.regionsCountry
    )
    if (!countryData) return []
    const regionsList = countryData.regions.reduce(
      (result, { name, shortCode }) => {
        result.push({ label: t(name) || name, code: shortCode || name })
        return result
      },
      [] as LabelAndCode[]
    )

    if (props.regionsCountry === 'JP') {
      return regionsList.sort(
        (a, b) => parseInt(a.code || '0') - parseInt(b.code || '0')
      )
    } else {
      return regionsList
    }
  } else {
    const translatedCountryNamesAndCodes = countries.map(country => ({
      label: t(country.label),
      code: country.code,
    }))

    return sortCountries(translatedCountryNamesAndCodes, locale.value)
  }
})

const sortCountries = (countries: LabelAndCode[], locale) => {
  return countries.sort((a, b) => {
    // We want certain locales to have certain countries ordered near the top
    const priorityCountriesByLocale = {
      zh: ['JP', 'CN', 'TW', 'HK', 'MO', 'MY', 'SG'],
      default: ['JP'],
    }

    const priorityCountries: string[] = priorityCountriesByLocale[
      locale || 'default'
    ] || ['JP']

    for (const country of priorityCountries) {
      if (a.code === country) return -1
      if (b.code === country) return 1
    }

    // Sort other countries based on the label in the 'alphabet' of the selected locale
    return a.label.localeCompare(b.label, locale)
  })
}

const inputType = computed(() => {
  return props.regionsCountry ? 'off' : 'country-name'
})
</script>
<style>
.error-border {
  border: 1px solid rgba(220, 38, 38, 1);
}

.error-text {
  color: rgba(220, 38, 38, 1);
}

.multiselect {
  height: 4rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --ms-option-font-size: 1rem;
}

/* To fix a bug with @vueform/multiselect in Chrome where the dropdown caret icon otherwise appears as a square */
.multiselect-caret {
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
}

/* To fix a bug with @vueform/multiselect in Chrome where the dropdown clear icon otherwise appears as a square */
.multiselect-clear-icon {
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
}

/* To fix a bug with @vueform/multiselect in Chrome where some icons appear as a square */
.multiselect-caret,
.multiselect-clear-icon {
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
}

.multiselect.is-active {
  box-shadow: none;
}

.multiselect-dropdown {
  @apply !border-[1px] !border-black;
}

.multiselect.is-open {
  @apply !border-[1px] !border-black;
}

@media (min-width: 640px) {
  .multiselect {
    margin-top: 1rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    --ms-option-font-size: 1.125rem;
  }
}

@media (min-width: 768px) {
  .multiselect {
    font-size: 1.25rem;
    line-height: 1.75rem;
    --ms-option-font-size: 1.25rem;
  }
}

.multiselect-option.is-selected.is-pointed {
  /* orange */
  background: #ff673a;
}

.multiselect-option.is-selected {
  /* orange */
  background: #ff673a;
}

.multiselect-option.is-pointed {
  /* peach */
  background: #ffd2c1;
}
</style>
