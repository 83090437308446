export default function formatDateTime(date, language = navigator.language) {
  return new Date(date).toLocaleTimeString(language || 'en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'JST',
  })
}
