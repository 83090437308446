<template>
  <base-label
    :class="labelClass"
    class="relative mb-4 text-sm font-semibold md:mb-6 md:text-base md:font-medium"
    :for="name"
  >
    {{ label }}
    <span v-if="props.showAsterisk" class="text-red-600">*</span>
    <select
      :name="name"
      :value="modelValue"
      v-bind="$attrs"
      class="mt-2 block !h-[50px] w-full bg-white text-sm font-normal focus:border-[1px] focus:border-black focus:outline-none md:mt-4 md:text-base md:font-medium"
      :class="{
        'text-gray-400': modelValue === null && !v$.value.$dirty,
        '!border-[1px] border-red-600 text-red-600':
          !!v$.value.$errors.length && v$.value.$dirty,
        'border-[1px] border-lightgray': borderAlwaysOn,
      }"
      @input="handleCustomInput(($event?.target as HTMLInputElement).value)"
    >
      <option :disabled="!allowEmpty" value>
        {{
          !!v$.value.$errors.length
            ? v$.value.$errors[0]?.$message
            : placeholder
        }}
      </option>
      <option
        v-for="(value, key) in optionsObject"
        :key="key"
        :value="key"
        :selected="modelValue == key"
      >
        {{ value }}
      </option>
    </select>
    <icon-caret
      class="absolute bottom-4 right-[18px] w-[10px] text-[#999] transition-transform duration-200"
    />
  </base-label>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import humanize from '@/utils/humanize'
import useValidations, { ModelValue } from '@/utils/setup/useValidations'
import { computed } from 'vue'
import IconCaret from '@/components/icons/IconCaret.vue'

const props = withDefaults(
  defineProps<{
    allowEmpty?: boolean
    modelValue?: number | string | null
    label?: string
    labelClass?: string | string[]
    required?: boolean
    placeholder?: string
    options: string[] | { [key: string]: string }
    borderAlwaysOn?: boolean
    showAsterisk?: boolean
  }>(),
  {
    allowEmpty: true,
    placeholder: 'Select',
    required: false,
    modelValue: null,
    borderAlwaysOn: false,
    showAsterisk: false,
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', value: ModelValue): void
}>()

const { handleCustomInput, v$ } = useValidations(props, emit)

// computed
const name = computed(() => props.label?.toLowerCase())
const optionsObject = computed(() => {
  // If options is not an array, just return it
  if (!Array.isArray(props.options)) return props.options

  // Otherwise, convert it to an Object using index as keys
  return Object.fromEntries(props.options.map(el => [el, humanize(el)]))
})
</script>

<style lang="scss" scoped>
select {
  &:focus {
    + svg {
      @apply rotate-180;
    }
  }
}
</style>
