import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { key } from './store'
import store from './store'
import i18n from './i18n'
import sentry from './sentry'
import { config } from './constants'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'

const app = createApp(App)

// Faro (Grafana's Frontend Observability Solution)
initializeFaro({
  url: `https://faro-collector-prod-ap-southeast-0.grafana.net/collect/${config.faro.appKey}`,
  app: {
    name: 'Checkin',
    version: config.appVersion,
    environment: config.environment,
  },
  instrumentations: [...getWebInstrumentations()],
})

// Sentry
sentry(app, router)

// Globally register all `_base`-prefixed components
import globals from '@/components/globals'
globals(app)

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
app.component('font-awesome-icon', FontAwesomeIcon)

// MultiSelect
import Multiselect from '@vueform/multiselect'
app.component('multi-select', Multiselect)

app.use(i18n).use(store, key).use(router).mount('#app')

// register custom service worker to handle app updates gracefully
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').catch(registrationError => {
      console.error('SW registration failed: ', registrationError)
    })

    // listen to controllerchange to handle the scenario where a new
    // service worker takes over the current one
    let refreshing = false
    navigator.serviceWorker.addEventListener('controllerchange', async () => {
      if (!refreshing) {
        await store.dispatch('setAlert', {
          title: i18n.global.t('alerts.warnings.warning'),
          messages: [i18n.global.t('alerts.warnings.newVersionMessage')],
          buttonText: i18n.global.t('alerts.actions.refresh'),
          buttonAction: () => { window.location.reload() },
        })
        refreshing = true
      }
    })
  })
}