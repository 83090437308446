<template>
  <component :is="component" v-bind="alert.props" />
</template>

<script setup lang="ts">
import AlertModal from '@/components/AlertModal.vue'
import EmailReusedModal from './modals/EmailReusedModal.vue'
import VerifyCheckinGuestAssignmentModal from './modals/VerifyCheckinGuestAssignmentModal.vue'
import VerifyCheckinGuestReassignmentModal from './modals/VerifyCheckinGuestReassignmentModal.vue'
import { computed } from 'vue'

const props = defineProps<{ alert: { componentName: string; props: Object } }>()

const knownComponents = {
  EmailReusedError: EmailReusedModal,
  VerifyCheckinGuestAssignmentModal: VerifyCheckinGuestAssignmentModal,
  VerifyCheckinGuestReassignmentModal: VerifyCheckinGuestReassignmentModal,
  default: AlertModal,
}

const component = computed(
  () => knownComponents[props.alert.componentName || 'default']
)
</script>
