<template>
  <div v-if="showDebugger">
    <div
      class="absolute bottom-0 flex justify-center gap-5 rounded-t-lg bg-red-500 p-2 px-10 text-center text-white opacity-50 sm:w-full sm:rounded-t-none sm:p-4"
    >
      <p v-if="usingAlternateApi">
        [API]
        <strong>{{ apiServer }}</strong>
        server
      </p>
      <p
        class="cursor-pointer border-b border-white"
        @click="$store.dispatch('setApiServer', null)"
      >
        Reset
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { config } from '@/constants'
import { key } from '@/store'

export default {
  name: 'DebugInfo',

  setup() {
    const store = useStore(key)

    const apiServer = computed(() => store.getters.apiServer)

    const usingAlternateApi = computed(
      () => !!config.apiServers[apiServer.value]
    )

    const showDebugger = computed(() => usingAlternateApi.value)

    return {
      showDebugger,
      usingAlternateApi,
      apiServer,
    }
  },
}
</script>
