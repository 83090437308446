import Repository from './repository'

export default {
  getAdminProperties() {
    return Repository.get('/admin/properties')
  },
  getProperty({ passCode }) {
    if (passCode) return Repository.get(`/properties/${passCode}`)
  },
  updateProperty(property) {
    return Repository.patch(`/admin/properties/${property.id}`, {
      property,
    })
  },
}
