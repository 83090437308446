import saveStateToStorage from '@/utils/saveStateToStorage'
import getSavedState from '@/utils/getSavedState'
import { Minor } from '@/types/api/types'
import MinorsRepository from '@/api/repositories/minors'
import {
  getters as propertyGetters,
  state as propertyState,
} from './properties'
import {
  getters as bookingGetters,
  actions as bookingActions,
  state as bookingState,
} from './bookings'
import { CurrentStep } from './checkins'
import { Minors } from './store'

export const state: Minors = {
  minor: getSavedState('minor'),
}

export const getters = {
  minor(state: Minors) {
    return state.minor
  },
  checkinSteps(state: Minors): { name: string; messageKey: string }[] {
    if (!state.minor) return []
    const isAtProperty = propertyGetters.atProperty(propertyState)

    const minorFlows = {
      atProperty: [
        { name: 'MinorGuestInfo', messageKey: 'pages.checkin.steps.guestInfo' },
        { name: 'MinorIdInfo', messageKey: 'pages.checkin.steps.id' },
        {
          name: 'MinorMugshotCapture',
          messageKey: 'pages.checkin.steps.mugshot',
        },
      ],
      notAtProperty: [
        { name: 'MinorGuestInfo', messageKey: 'pages.checkin.steps.guestInfo' },
        { name: 'MinorIdInfo', messageKey: 'pages.checkin.steps.id' },
      ],
    }

    return minorFlows[isAtProperty ? 'atProperty' : 'notAtProperty']
  },
  currentStep(_, getters, rootState): CurrentStep {
    const defaultStep = {
      number: 0,
      messageKey: 'general.start',
      notFound: true,
    }

    const steps = getters.checkinSteps

    return {
      totalStepCount: steps.length,
      ...steps.reduce((acc, { name, messageKey }, idx) => {
        const foundCurrentRoute = name === rootState.route?.name
        return foundCurrentRoute
          ? {
            number: idx,
            messageKey,
          }
          : acc
      }, defaultStep),
    }
  },
}

export const mutations = {
  SET_MINOR(state: Minors, minor: Minors['minor']) {
    state.minor = minor
    saveStateToStorage<Minors>('minor', state.minor)
  },

  RESET_MINOR(state: Minors) {
    state.minor = null
    saveStateToStorage<Minors>('minor', state.minor)
  },
}

export const actions = {
  async setMinor({ commit }, minor) {
    commit('SET_MINOR', minor)
  },
  async createMinor({ commit, dispatch }, minor: Partial<Omit<Minor, 'id'>>) {
    if (!bookingGetters.booking(bookingState)?.code)
      throw Error('Cannot create Minor without a Booking Code')
    const originalMinorIds =
      bookingGetters.booking(bookingState)?.minors?.map((m: Minor) => m.id) ||
      []
    const response = await MinorsRepository.postMinor({
      bookingCode: bookingGetters.booking(bookingState)?.code || '',
      minor,
    })

    const updatedBooking = response.data
    if (updatedBooking)
      dispatch('bookings/setBooking', updatedBooking, { root: true })

    const newMinor = updatedBooking.minors.find(
      (m: Minor) => !originalMinorIds.includes(m.id)
    )
    commit('SET_MINOR', newMinor)
    return updatedBooking ? newMinor : undefined
  },
  async updateMinor({ commit, dispatch }, minor: Partial<Minor>) {
    if (!bookingGetters.booking(bookingState)?.code)
      throw Error('Cannot update Minor without a Booking Code')
    const response = await MinorsRepository.patchMinor({
      minor,
      bookingCode: bookingGetters.booking(bookingState)?.code || '',
    })
    const updatedMinor = response.data

    dispatch('bookings/addMinorToBooking', updatedMinor, { root: true })
    commit('SET_MINOR', updatedMinor)
    return updatedMinor
  },
  async createOrUpdateMinor({ dispatch }, minor: Partial<Minor>) {
    const newMinor = await dispatch(
      minor.id ? 'updateMinor' : 'createMinor',
      minor
    )
    return newMinor
  },
  resetMinor({ commit }) {
    commit('RESET_MINOR')
  },
}
