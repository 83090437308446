<template>
  <modal ref="goBackModal" class="h-11/12 overflow-y-hidden">
    <div class="flex flex-col items-center justify-center gap-8">
      <p class="text-center text-sm font-medium md:text-lg">
        {{ $t('modals.backToReservationConfirmation.message') }}
      </p>
      <img
        src="@/assets/illustrations/obasan_going_left.png"
        alt="Stylish Obasan makes an exit"
        class="h-44 w-44 object-contain"
      />
      <div class="flex w-full flex-row justify-between">
        <base-button small narrower-min-width secondary @click.native="close">
          {{ $t('modals.backToReservationConfirmation.leftButtonText') }}
        </base-button>
        <base-button
          small
          narrower-min-width
          data-cy="confirmExit"
          @click.native="router.push({ name: 'ReservationConfirmation' })"
        >
          {{ $t('modals.backToReservationConfirmation.rightButtonText') }}
        </base-button>
      </div>
    </div>
  </modal>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Modal from '@/components/modals/Modal.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const goBackModal = ref<InstanceType<typeof Modal>>()
const show = () => {
  goBackModal.value?.show()
}
const close = () => {
  goBackModal.value?.close()
}

defineExpose({ show })
</script>
