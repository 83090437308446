<template>
  <component v-bind="$attrs" :is="component" v-if="component" />
  <FontAwesomeIcon
    v-else-if="source === 'font-awesome'"
    v-bind="$attrs"
    :icon="name"
  />
  <span
    v-else-if="source === 'custom'"
    v-bind="$attrs"
    :class="customIconClass"
  />
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library as fontAwesomeIconLibrary } from '@fortawesome/fontawesome-svg-core'
import camelCase from 'lodash/camelCase'
import {
  faSignInAlt,
  faSignOutAlt,
  faBackspace,
  faGlobeAmericas,
} from '@fortawesome/free-solid-svg-icons'

// https://fontawesome.com/icons
fontAwesomeIconLibrary.add(
  faSignInAlt,
  faSignOutAlt,
  faBackspace,
  faGlobeAmericas
)
export default {
  components: {
    FontAwesomeIcon,
  },
  inheritAttrs: false,
  props: {
    component: {
      type: Object,
      required: false,
    },
    source: {
      type: String,
      default: 'font-awesome',
      required: false,
    },
    name: {
      type: [String, Array],
      required: false,
    },
  },
  computed: {
    // Gets a CSS module class, e.g. iconCustomLogo
    customIconClass() {
      return this.$style[camelCase('icon-custom-' + this.name)]
    },
  },
}
</script>
