import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import store from '@/store'
import NProgress from 'nprogress/nprogress'
import { storeMiddleware } from './storeMiddleware'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    NProgress.configure({ showSpinner: false })
    NProgress.start()
  }

  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some(route => route.meta.authRequired)

  // If auth is required and user is not logged in
  if (authRequired && !store.getters['auth/loggedIn']) return redirectToLogin()

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'Login', query: { redirectFrom: routeTo.fullPath } })
  }

  // ================================
  try {
    await storeMiddleware(routeTo, routeFrom, next)
  } catch (error) {
    // If redirected, just return.
    return
  }

  return next()
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  // For each matched route...
  routeTo.matched.forEach(async route => {
    try {
      await new Promise<void>((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (routeFrom.name === args[0].name) {
                // Complete the animation of the route progress bar.
                NProgress.done()
              }
              // Complete the redirect.
              next(...(args as []))
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    } catch (error) {
      // If a `beforeResolve` hook chose to redirect, just return.
      return
    }
  })

  // If we reach this point, continue resolving the route.
  next()
})

// When each route is finished evaluating...
router.afterEach(() => {
  // router.afterEach((routeTo, routeFrom) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
