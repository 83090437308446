// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

export default Vue => {
  const componentFiles = import.meta.glob(['./*.vue'], { eager: true })

  Object.entries(componentFiles).forEach(([path, m]) => {
    const componentName = path
      // Remove the "./_" from the beginning
      .replace(/^\.\/_/, '')
      // Remove the file extension from the end
      .replace(/\.\w+$/, '')
      // Split up kebabs
      .split('-')
      // Upper case
      .map(kebab => kebab.charAt(0).toUpperCase() + kebab.slice(1))
      // Concatenated
      .join('')

    // Globally register the component
    Vue.component(componentName, m.default)
  })
}
