<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="242.145"
    height="34.143"
    viewBox="0 0 242.145 34.143"
  >
    <defs>
      <clipPath id="a">
        <rect class="a" width="242.145" height="34.143"></rect>
      </clipPath>
      <clipPath id="c">
        <rect class="a" width="11.131" height="32.423"></rect>
      </clipPath>
      <clipPath id="d">
        <rect class="a" width="23.764" height="10.933"></rect>
      </clipPath>
    </defs>
    <g transform="translate(121.073 17.072)">
      <g class="b" transform="translate(-121.073 -17.072)">
        <g class="c">
          <g class="c">
            <g class="d" transform="translate(218.382 0.888)">
              <g class="e">
                <rect
                  class="f"
                  width="11.131"
                  height="32.423"
                  fill-opacity="0.34"
                ></rect>
              </g>
            </g>
            <g class="d" transform="translate(218.382 22.398)">
              <g class="g">
                <rect
                  class="f"
                  width="23.764"
                  height="10.933"
                  fill-opacity="0.34"
                ></rect>
              </g>
            </g>
          </g>
          <rect
            width="6.76"
            height="32.423"
            transform="translate(122.78 0.888)"
          ></rect>
          <path
            d="M150.952,1.3H134.7V7.446h9.492v26.29h6.76V7.446h9.56V1.3Z"
            transform="translate(-42.717 -0.412)"
          ></path>
          <rect
            width="6.76"
            height="32.423"
            transform="translate(173.176 0.888)"
          ></rect>
          <path
            d="M277.362,1.3h0V19.737L270.943,1.3H263.5l12.77,32.436h7.853V1.3Z"
            transform="translate(-83.564 -0.412)"
          ></path>
          <g class="c">
            <path
              d="M19.8,15.5a24.583,24.583,0,0,0-6.487-1.98,5.747,5.747,0,0,1-4.1-2.253A3.961,3.961,0,0,1,8.672,8.4c.341-2.322,2.936-2.731,3.756-2.868a6.487,6.487,0,0,1,2.117.137,12.5,12.5,0,0,1,5.395,3L23.832,4.23a13.521,13.521,0,0,0-1.707-1.5A14.135,14.135,0,0,0,18.642.884,15.092,15.092,0,0,0,13.179,0,13.316,13.316,0,0,0,6.829,1.362,9.355,9.355,0,0,0,3,4.981,9.97,9.97,0,0,0,1.707,9.9,8.058,8.058,0,0,0,4.1,15.907,12.77,12.77,0,0,0,9.97,19.117a21.442,21.442,0,0,1,5.463,1.98,3.346,3.346,0,0,1,1.844,3,3.073,3.073,0,0,1-.751,2.049,4.575,4.575,0,0,1-1.844,1.3,5.941,5.941,0,0,1-2.185.41A9.014,9.014,0,0,1,7.58,26.492,16.8,16.8,0,0,1,4.37,23.9L0,28.882A17.55,17.55,0,0,0,2.868,31.34a16.048,16.048,0,0,0,4.166,2.049,18.1,18.1,0,0,0,5.6.751,14.067,14.067,0,0,0,6.146-1.3,10.994,10.994,0,0,0,4.3-3.551,8.809,8.809,0,0,0,1.571-5.122,9.765,9.765,0,0,0-1.366-5.395A9.628,9.628,0,0,0,19.8,15.5"
              transform="translate(0 0.004)"
            ></path>
            <path
              d="M225.7,4.916A15.843,15.843,0,0,0,214.02,0a16.32,16.32,0,0,0-6.487,1.3,15.707,15.707,0,0,0-5.19,3.619,16.867,16.867,0,0,0-3.414,5.395,18.165,18.165,0,0,0-1.229,6.76,18.232,18.232,0,0,0,1.229,6.692,16.593,16.593,0,0,0,3.483,5.463,15.979,15.979,0,0,0,5.19,3.619,16.867,16.867,0,0,0,12.906,0,15.979,15.979,0,0,0,5.19-3.619,16.594,16.594,0,0,0,3.483-5.463,18.232,18.232,0,0,0,1.229-6.692,18.233,18.233,0,0,0-1.229-6.76A16.662,16.662,0,0,0,225.7,4.916M222.419,22.4a9.7,9.7,0,0,1-3.278,3.961,9.355,9.355,0,0,1-10.175,0,9.833,9.833,0,0,1-3.278-3.961A12.7,12.7,0,0,1,204.528,17a12.5,12.5,0,0,1,1.161-5.395,9.7,9.7,0,0,1,3.278-3.892,9.492,9.492,0,0,1,10.175,0,9.492,9.492,0,0,1,3.278,3.892A12.77,12.77,0,0,1,223.512,17a12.906,12.906,0,0,1-1.093,5.395"
              transform="translate(-62.696 0.001)"
            ></path>
            <path
              d="M108.611,25.092h0a9.424,9.424,0,0,1-1.366,1.229,9.355,9.355,0,0,1-10.175,0,9.833,9.833,0,0,1-3.278-3.961,12.7,12.7,0,0,1-1.161-5.395,12.565,12.565,0,0,1,1.093-5.326A9.7,9.7,0,0,1,97,7.747a9.492,9.492,0,0,1,10.175,0,9.355,9.355,0,0,1,2.185,2.117l.956,1.161,4.644-4.575a14.545,14.545,0,0,0-5.395-4.644A16.935,16.935,0,0,0,101.919.1a16.389,16.389,0,0,0-6.692,1.3,15.228,15.228,0,0,0-5.122,3.619,16.662,16.662,0,0,0-3.346,5.463,19.052,19.052,0,0,0-1.161,6.76,18.233,18.233,0,0,0,2.049,8.741,15.365,15.365,0,0,0,5.736,6.009,17.072,17.072,0,0,0,13.862,1.3,17.413,17.413,0,0,0,4.575-2.458A16.047,16.047,0,0,0,115.1,27.55l-4.643-4.37a25.747,25.747,0,0,1-1.912,1.98"
              transform="translate(-27.145 -0.03)"
            ></path>
            <path
              d="M51.956,20.42H63.5V14.343H51.956V7.378H67.73V1.3H45.4V33.736H67.73V27.59H51.956Z"
              transform="translate(-14.398 -0.412)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style>
.a {
  fill: none;
}
.b,
.d {
  isolation: isolate;
}
.c {
  clip-path: url(#a);
}
.d {
  mix-blend-mode: multiply;
}
.e {
  clip-path: url(#c);
}
.f {
  fill: #000;
}
.g {
  clip-path: url(#d);
}
</style>
