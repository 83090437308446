import request from '@/utils/request'

export type LogInArguments = {
  email?: string
  password?: string
}

export function logIn({ email, password }: LogInArguments) {
  return request({
    url: '/admin/auth/sign_in',
    method: 'post',
    data: { email, password },
  })
}

export function logOut() {
  try {
    return request({
      url: '/admin/auth/sign_out',
      method: 'delete',
    })
  } catch (error) {
    console.warn(error)
  }
}
