import BookingsRepository, {
  DeleteBookingInviteArgs,
  PostBookingInviteArgs,
} from '@/api/repositories/bookings'
import saveStateToStorage from '@/utils/saveStateToStorage'
import errorDispatcher from '@/utils/errorDispatcher'
import getSavedState from '@/utils/getSavedState'
import router from '@/router'
import { Booking, Checkin, Invite, Minor } from '@/types/api/types'
import { Bookings } from './store'

export const state: Bookings = {
  booking: getSavedState<Booking>('booking'),
}

export const getters = {
  booking(state: Bookings) {
    return state.booking
  },
  minors(state: Bookings) {
    return state.booking?.minors
  },
  minorFromId(state: Bookings) {
    return (idToFind: number) =>
      state.booking?.minors.find(({ id }) => id === idToFind)
  },
  allCheckinsWillBeCompleted(state: Bookings, _, __, rootGetters) {
    const currentCheckin = rootGetters['checkins/currentCheckin']

    const completedAdultCheckins =
      state.booking?.checkins.filter(checkin => {
        if (checkin.status !== 'completed') return false

        return currentCheckin ? checkin.id != currentCheckin.id : true
      }) || []

    const completedMinors =
      state.booking?.minors.filter(minor => {
        const hasAllRequiredFields = (
          [
            'id',
            'dateOfBirth',
            'firstName',
            'lastName',
            'gender',
            'nationality',
            'photoKey', // while actually not required from minors, when skipped this field gets set to the string 'skipped'
          ] as (keyof Minor)[]
        )
          .concat(
            minor.nationality !== 'JP' && minor.country !== 'JP'
              ? (['idPhotoKey', 'passportNo'] as (keyof Minor)[])
              : []
          )
          .every(field => Boolean(minor[field]))

        return hasAllRequiredFields
      }) || []

    // we deduct 1 since this could be the checkin or the minor that could complete the entire booking
    const numberOfAdults = currentCheckin
      ? (state.booking?.numAdult || 1) - 1
      : Number(state.booking?.numAdult)
    const numberOfMinors = Number(state.booking?.numChild)

    return (
      completedAdultCheckins.length >= numberOfAdults &&
      completedMinors.length >= numberOfMinors
    )
  },
}

export const mutations = {
  SET_BOOKING(state: Bookings, booking: Booking) {
    state.booking = booking
    saveStateToStorage<Bookings>('booking', state.booking)
  },

  ADD_CHECKIN(state: Bookings, checkin: Checkin) {
    if (!checkin.id) return

    if (state.booking) {
      const index = state.booking.checkins.findIndex(
        (ci: Checkin) => ci.guest?.email === checkin.guest?.email
      )
      index === -1
        ? state.booking?.checkins.push(checkin)
        : (state.booking.checkins[index] = checkin)
      saveStateToStorage<Bookings>('booking', state.booking)
    }
  },
  ADD_MINOR(state: Bookings, minor: Minor) {
    if (!minor.id) return

    if (state.booking) {
      const index = state.booking.minors.findIndex(
        (m: Minor) => m.id === minor.id
      )
      index === -1
        ? state.booking?.minors.push(minor)
        : (state.booking.minors[index] = minor)
      saveStateToStorage<Bookings>('booking', state.booking)
    }
  },

  RESET_BOOKING(state: Bookings) {
    state.booking = null
    saveStateToStorage<Bookings>('booking', state.booking)
  },
}

export const actions = {
  setBooking({ commit }, bookingData: Partial<Booking>) {
    commit('SET_BOOKING', bookingData)
  },
  async fetchBooking(
    {
      commit,
      rootGetters,
    }: { commit: Function; rootGetters: { [key: string]: string } },
    { bookingNo }: { bookingNo: string }
  ) {
    if (!bookingNo) return
    try {
      const response = await BookingsRepository.getBooking({
        bookingNo,
        propCode: rootGetters['properties/code'],
      })
      const booking = response.data
      commit('SET_BOOKING', booking)
      return booking
    } catch (error) {
      await errorDispatcher(
        error.type,
        () => router.push({ name: 'Home' }),
        error.metadata
      )
    }
  },
  addCheckin(
    { commit }: { commit: Function },
    { checkin }: { checkin: Checkin }
  ) {
    return commit('ADD_CHECKIN', checkin)
  },
  async fetchInvite(
    _,
    { bookingCode, inviteUuid }
  ): Promise<Invite | undefined> {
    try {
      const response = await BookingsRepository.getInvite({
        bookingCode,
        inviteUuid,
      })
      return response.data
    } catch (error) {
      if (error instanceof Error) {
        await errorDispatcher(error.type, () => router.push({ name: 'Home' }))
      }
    }
  },
  async createInvite(
    { commit },
    { bookingCode, invite }: PostBookingInviteArgs
  ): Promise<Booking> {
    const response = await BookingsRepository.createInvite({
      bookingCode,
      invite,
    })
    const booking = response.data
    commit('SET_BOOKING', booking)
    return booking
  },
  async deleteInvite(
    { commit },
    { bookingCode, inviteUuid }: DeleteBookingInviteArgs
  ): Promise<Booking> {
    const response = await BookingsRepository.deleteInvite({
      bookingCode,
      inviteUuid,
    })
    const booking = response.data
    commit('SET_BOOKING', booking)
    return booking
  },
  async sendInviteEmails({ }, { bookingCode }: { bookingCode: string }) {
    const response = await BookingsRepository.sendInviteEmails({ bookingCode })
    return response.data
  },
  resetBooking({ commit }: { commit: Function }) {
    return commit('RESET_BOOKING')
  },
  async checkout(
    { rootGetters }: { rootGetters: { [key: string]: string } },
    { roomNumber, roomCode }: { roomNumber: number; roomCode: string }
  ) {
    const response = await BookingsRepository.checkout({
      roomNumber,
      roomCode,
      propCode: rootGetters['properties/code'],
    })
    return response.data
  },
  async addMinorToBooking({ commit }, updatedMinor: Partial<Minor>) {
    if (!state.booking?.code)
      throw Error('Cannot update Minor without a Booking Code')
    commit('ADD_MINOR', updatedMinor)
    return updatedMinor
  },
}
