<template>
  <squishable-thingy
    shallow
    :disabled="disabled"
    :class="{ fullWidth }"
    @click="goTo"
  >
    <button
      ref="theButton"
      class="pointer-events-auto"
      :class="{
        dimensionStyles: !circle && !small,
        spacingStyles: !circle && !small,
        textStyles: !small,
        primary: primary && !secondary,
        secondary,
        circle,
        round,
        primaryDisabled: primary && !secondary && disabled,
        secondaryDisabled: secondary && disabled,
        small,
        primaryRed: red && primary && !secondary && !disabled,
        secondaryRed: red && secondary && !disabled,
        fullWidth,
        narrowerMinWidth,
      }"
      v-bind="$attrs"
    >
      <div class="flex items-center justify-between">
        <div v-if="leftArrow" class="pr-2"><icon-arrow-left /></div>
        <div class="flex w-full justify-between">
          <div
            v-if="icon"
            class="flex justify-center"
            :class="{ 'w-max': !circle, 'w-full': circle }"
          >
            <base-icon
              :component="icon.component"
              :name="icon.name"
              :source="icon.source"
              class="mx-2 h-8"
              :class="icon.htmlClass"
            />
          </div>
          <p
            v-if="Boolean($slots.default)"
            class="flex w-full items-center justify-center whitespace-normal font-semibold"
          >
            <slot />
          </p>
        </div>
        <div v-if="rightArrow" class="pl-2"><icon-arrow-right /></div>
      </div>
    </button>
  </squishable-thingy>
</template>

<script lang="ts">
export default { inheritAttrs: false }
</script>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import SquishableThingy from '../SquishableThingy.vue'
import IconArrowLeft from '../icons/IconArrowLeft.vue'
import IconArrowRight from '../icons/IconArrowRight.vue'
import { ref } from 'vue'

type ButtonIconProps =
  | {
      component: any
      name?: string
      source?: string
      htmlClass?: string
    }
  | {
      component?: any
      name: string
      source: string
      htmlClass: string
    }

const props = withDefaults(
  defineProps<{
    to?: {
      name: string
      function: Function
    }
    icon?: ButtonIconProps
    // Variants
    primary?: boolean
    secondary?: boolean
    circle?: boolean
    round?: boolean
    disabled?: boolean
    small?: boolean
    leftArrow?: boolean
    rightArrow?: boolean
    fullWidth?: boolean
    narrowerMinWidth?: boolean
    red?: boolean
  }>(),
  {
    primary: true,
    secondary: false,
    small: false,
    circle: false,
    rounded: false,
    leftArrow: false,
    rightArrow: false,
    fullWidth: false,
    narrowerMinWidth: false,
    red: false,
  }
)

const theButton = ref<HTMLButtonElement>()

const router = useRouter()

const goTo = () => {
  if (props.to === undefined) return

  if (props.to?.name === 'back') router.go(-1)
  else if (props.to?.function) props.to['function']()
  else if (props.to) router.push(props.to)
}

defineExpose({ blur: () => theButton.value?.blur() })
</script>

<style lang="scss">
.primary {
  @apply border-[3px] border-transparent bg-black text-white active:bg-gray-800;
}

.secondary {
  @apply border-[3px] border-black bg-white text-black active:bg-gray-200;
}

.primaryDisabled {
  @apply border-darkgray bg-darkgray active:bg-darkgray;
}

.secondaryDisabled {
  @apply border-darkgray bg-white text-darkgray active:bg-white;
}

.circle {
  @apply h-24 w-24 rounded-full sm:min-w-0 md:py-[0px];
}

.dimensionStyles {
  @apply w-max min-w-[163px] sm:w-auto sm:min-w-[56px] md:min-w-[285px];
}

.spacingStyles {
  @apply px-[12px] py-[22px] md:px-[20px] md:py-[40px];
}

.textStyles {
  @apply text-[20px] font-semibold md:text-[32px];
}

.small {
  @apply min-w-[135px] border-[2px] p-[12px] text-[16px] font-medium md:w-max md:min-w-[285px] md:p-[24px] md:text-[24px];
}

.narrowerMinWidth {
  @apply md:min-w-[200px];
}

.round {
  @apply min-w-[240px] rounded-full px-[22px] md:px-[26px];
}

.fullWidth {
  @apply w-full;
}

.primaryRed {
  @apply bg-Error active:bg-red-800;
}

.secondaryRed {
  @apply border-[2px] border-Error bg-white text-Error active:bg-red-200;
}
</style>
