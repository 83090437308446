export const config = {
  appVersion: import.meta.env.PACKAGE_VERSION,
  environment: import.meta.env.MODE || 'development',
  apiURL:
    import.meta.env.VITE_API_URL || `http://${window.location.hostname}:3000`,
  apiServers: {
    staging: import.meta.env.VITE_API_STAGING_URL,
    qa: import.meta.env.VITE_API_QA_URL,
    development: import.meta.env.VITE_API_DEV_URL,
  },
  returnHomeTimeoutSecs: import.meta.env.VITE_RETURN_HOME_TIMEOUT_SECS || '30',
  apiVersion: import.meta.env.VITE_API_VERSION || 'v2',
  adminApiVersion: import.meta.env.VITE_ADMIN_API_VERSION || 'v1',
  cloudinary: {
    cloudName: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    uploadPreset: import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET,
    defaultAgreementKeys: {
      en: import.meta.env.VITE_CLOUDINARY_AGREEMENT_KEY_DEFAULT_EN,
      ja: import.meta.env.VITE_CLOUDINARY_AGREEMENT_KEY_DEFAULT_JA,
      zh: import.meta.env.VITE_CLOUDINARY_AGREEMENT_KEY_DEFAULT_ZH,
    },
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
  faro: {
    appKey: import.meta.env.VITE_FARO_APP_KEY,
  },
  supportedLocales: ['en', 'ja', 'zh'],
  defaultLocale: 'en',
  google: {
    geocodingApiKey: import.meta.env.VITE_GOOGLE_MAP_GEOCODING_API_KEY,
    geocodingEndpoint: import.meta.env.VITE_GOOGLE_MAP_GEOCODING_API_ENDPOINT,
  },
}
