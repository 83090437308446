import i18n from '@/i18n'
import store from '@/store'
import { phoneNumberKey } from '@/use/usePropertyPhoneNumber'
import formatDateTime from './formatDateTime'

type BookingErrorCodes = {
  BookingNotFound: 'BookingNotFound'
  BookingNotActive: 'BookingNotActive'
  BookingNotValidForCheckin: 'BookingNotValidForCheckin'
  BookingNotAllowedToCheckinYet: 'BookingNotAllowedToCheckinYet'
  BookingStatusNotValidForCheckin: 'BookingStatusNotValidForCheckin'
}
type ErrorMetadata = {
  stayStartsAt?: string
}

type ErrorMessagesByCode<Type> = {
  [Property in keyof Type]: {
    title: string
    messages: string[]
  }
}

export default async function errorDispatcher(
  errorCode: keyof BookingErrorCodes,
  actionFunction: () => any,
  metadata?: ErrorMetadata
) {
  const { t } = i18n.global // removed "|| i18n" since { t } cannot be destructed from it

  const ERROR_CODE_MESSAGES: ErrorMessagesByCode<BookingErrorCodes> = {
    BookingNotFound: {
      title: t('alerts.errors.titles.BookingNotFound'),
      messages: [
        t('alerts.errors.messages.BookingNotFound[0]'),
        t('alerts.errors.messages.BookingNotFound[1]', {
          phoneNumber: t(phoneNumberKey.value),
        }),
      ],
    },
    BookingNotActive: {
      title: t('alerts.errors.titles.BookingNotActive'),
      messages: [
        t('alerts.errors.messages.BookingNotActive[0]'),
        t('alerts.errors.messages.BookingNotActive[1]', {
          phoneNumber: t(phoneNumberKey.value),
        }),
      ],
    },
    BookingNotValidForCheckin: {
      title: t('alerts.errors.titles.BookingNotValidForCheckin'),
      messages: [
        t('alerts.errors.messages.BookingNotValidForCheckin[0]'),
        t('alerts.errors.messages.BookingNotValidForCheckin[1]', {
          phoneNumber: t(phoneNumberKey.value),
        }),
      ],
    },
    BookingStatusNotValidForCheckin: {
      title: t('alerts.errors.titles.BookingStatusNotValidForCheckin'),
      messages: [
        t('alerts.errors.messages.BookingStatusNotValidForCheckin[0]'),
        t('alerts.errors.messages.BookingStatusNotValidForCheckin[1]', {
          phoneNumber: t(phoneNumberKey.value),
        }),
      ],
    },
    BookingNotAllowedToCheckinYet: {
      title: t('alerts.errors.titles.BookingNotAllowedToCheckinYet'),
      messages: [
        metadata?.stayStartsAt
          ? t('alerts.errors.messages.BookingNotAllowedToCheckinYet[0]', {
            formattedDate: formatDateTime(
              metadata?.stayStartsAt,
              i18n.global.locale?.value || 'en'
            ),
          })
          : t('alerts.errors.messages.BookingNotAllowedToCheckinYet[2]'),
        t('alerts.errors.messages.BookingNotAllowedToCheckinYet[1]', {
          phoneNumber: t(phoneNumberKey.value),
        }),
      ],
    },
  }

  await store.dispatch(
    'setAlert',
    {
      ...ERROR_CODE_MESSAGES[errorCode],
      buttonAction: actionFunction,
      buttonText: t('general.close'),
    },
    { root: true }
  )
}
