<template>
  <div
    v-if="eitherBackOrNextIsShowing"
    class="pointer-events-none z-20 flex w-full justify-center pt-10 md:fixed md:left-0 md:py-2"
  >
    <div
      class="bottom-0 w-full bg-gradient-to-t from-powder via-powder/90 to-transparent pb-4 md:fixed md:max-w-[1080px] md:px-16 md:pb-20"
    >
      <div class="flex justify-between">
        <base-button
          v-if="back?.show"
          ref="backButton"
          :left-arrow="back?.arrow"
          :secondary="back?.secondary"
          :disabled="backDisabled"
          data-cy="back"
          :to="backTo"
        >
          {{ $t(back?.label) }}
        </base-button>
        <base-button
          v-if="next?.show"
          ref="nextButton"
          :full-width="!back?.show"
          :right-arrow="next?.arrow"
          :secondary="next?.secondary"
          :to="nextTo"
          :disabled="nextDisabled"
          data-cy="next"
        >
          {{ $t(next?.label) }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useKeyboardInput from '@/use/useKeyboardInput'
import { useStore } from 'vuex'
import { computed, ref, watch } from 'vue'
import { key } from '@/store'
import _BaseButton from './globals/_BaseButton.vue'

const nextButton = ref<InstanceType<typeof _BaseButton>>()
const backButton = ref<InstanceType<typeof _BaseButton>>()

const store = useStore(key)
const appLoading = computed(() => store.getters.appLoading)
const back = computed(() => store.getters['wayfinder/back'])
const next = computed(() => store.getters['wayfinder/next'])
const backDisabled = computed(() => appLoading.value || !back.value?.enabled)
const nextDisabled = computed(() => appLoading.value || !next.value?.enabled)
const backTo = computed(() => ({
  function: backDisabled.value ? () => {} : backAction,
}))
const nextTo = computed(() => ({
  function: nextDisabled.value ? () => {} : next.value.action,
}))
const eitherBackOrNextIsShowing = computed<boolean>(
  () => back.value?.show || next.value?.show
)
const backAction = () => {
  backButton.value?.blur()
  back.value.action()
}

const emit = defineEmits<{
  (e: 'change', value: { isShowingBackNextNav: boolean }): void
}>()

watch(
  () => eitherBackOrNextIsShowing.value,
  showing => {
    emit('change', { isShowingBackNextNav: showing })
  },
  { immediate: true }
)

const keyboardInput = e => {
  if (e.key === 'Enter') {
    if (
      !appLoading.value &&
      next.value?.show &&
      next.value?.enabled &&
      next.value?.action &&
      next.value?.listenOnKeypressEnter
    ) {
      next.value?.action()
    }
  }
}

useKeyboardInput(keyboardInput)
</script>
