import { reactive, onUpdated } from 'vue'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { required } from '@/utils/i18nValidators'
import inputValidationsPerType, { VALIDATORS } from '../inputValidationsPerType'

export type ModelValue = number | string | null

export type ValidationProps = {
  modelValue: ModelValue
  required: boolean
  type?: keyof typeof VALIDATORS
}

export type ValidationEmits = {
  (e: 'update:modelValue', value: ModelValue): void
}

export default function (
  props: ValidationProps,
  emit: ValidationEmits,
  afterHandleCustomInput?: (value: ModelValue) => void | Promise<void>
) {
  const state = reactive({
    value: props.modelValue,
  })

  onUpdated(() => {
    state.value = props.modelValue
  })

  const rules: ValidationArgs = {
    value: {
      ...(props.required ? { required } : {}),
      ...inputValidationsPerType(props.type),
    },
  }

  const handleCustomInput = async (value: ModelValue) => {
    if (value === undefined) return

    state.value = value
    emit('update:modelValue', value)
    if (afterHandleCustomInput) await afterHandleCustomInput(value)
  }

  const v$ = useVuelidate(rules, state)

  return { handleCustomInput, state, v$ }
}
