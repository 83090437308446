import { computed } from 'vue'
import store from '@/store'

export const phoneNumberKey = computed(() => {
  const bookingPropertyName = store.getters['bookings/booking']?.propertyName
  const property = store.getters['properties/property']
  const phoneNumberKey = bookingPropertyName
    ? `general.propertyPhoneNumbers.${bookingPropertyName}` // try to get the property from the booking
    : property?.name
    ? `general.propertyPhoneNumbers.${property?.name}` // if not, try the property that we're currently at
    : 'general.phoneNumber' // finally, just use the general fallback phone
  return phoneNumberKey
})
