<template>
  <header-with-logo v-if="notOnAProgressStep">
    <template #right>
      <slot name="right" />
    </template>
  </header-with-logo>
  <header-with-obasan v-else :current-step="inProgressStep">
    <template #left>
      <div
        class="flex flex-col"
        :class="{ 'flex-col-reverse': nameOnTopLanguage }"
      >
        <p v-if="!isMobile" class="text-sm font-normal">
          {{ $t(name ? 'pages.checkin.nav.named' : 'pages.checkin.nav.anon') }}
        </p>
        <p class="text-sm font-semibold sm:text-lg">
          {{ name || $t('pages.checkin.nav.placeholder') }}
        </p>
      </div>
    </template>
    <template #right>
      <slot name="right" />
    </template>
  </header-with-obasan>
</template>

<script setup lang="ts">
import HeaderWithObasan from '@/components/HeaderWithObasan.vue'
import { computed, watch } from 'vue'
import { Guest, Minor } from '@/types/api/types'
import { useStore } from 'vuex'
import { key } from '@/store'
import HeaderWithLogo from './HeaderWithLogo.vue'
import isMobileDetector from '@/utils/isMobile'
import { CurrentStep } from '@/store/modules/checkins'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()

const emit = defineEmits<{
  (e: 'change', value: { isShowingObasanHeader: boolean }): void
}>()

const isMobile = computed(isMobileDetector)

const store = useStore(key)

const inProgressCheckinStep = computed<CurrentStep>(
  () => store.getters['checkins/currentStep']
)
const notOnACheckinStep = computed(
  () => inProgressCheckinStep.value.notFound == true
)
watch(
  () => notOnACheckinStep.value,
  () => {
    store.dispatch('setThemeColor', {
      color: !notOnACheckinStep.value ? '#ffd2c1' : '#f9f2ed',
    })
    emit('change', { isShowingObasanHeader: !notOnACheckinStep.value })
  }
)

const inProgressMinorCheckinStep = computed<CurrentStep>(
  () => store.getters['minors/currentStep']
)
const notOnAMinorStep = computed(
  () => inProgressMinorCheckinStep.value.notFound == true
)
watch(
  () => notOnAMinorStep.value,
  () => {
    store.dispatch('setThemeColor', {
      color: !notOnAMinorStep.value ? '#ffd2c1' : '#f9f2ed',
    })
    emit('change', { isShowingObasanHeader: !notOnAMinorStep.value })
  }
)

const notOnAProgressStep = computed(
  () => notOnACheckinStep.value && notOnAMinorStep.value
)

const inProgressStep = computed(() => {
  if (notOnAMinorStep.value) return inProgressCheckinStep.value
  return inProgressMinorCheckinStep.value
})

const name = computed(() => {
  return notOnAMinorStep.value ? guest.value.fullName : minor.value.firstName
})

const nameOnTopLanguage = computed<boolean>(() =>
  ['ja', 'zh'].includes(locale.value)
)

const guest = computed<Guest>(() => store.getters['checkins/checkin']?.guest)
const minor = computed<Minor>(() => store.getters['minors/minor'])
</script>
