<template>
  <div
    v-if="alert || hasSlot"
    class="shadowStyles translate-center fixed z-50 flex w-10/12 flex-col items-center gap-y-6 bg-white px-6 py-8 font-semibold sm:gap-y-12 sm:px-16 sm:py-16 md:max-w-[952px]"
    :class="alert.classes"
    v-bind="$attrs"
  >
    <div
      data-cy="alertModalDismiss"
      class="absolute right-2 top-2 text-base md:right-12 md:top-6 md:text-xl"
      @click="closeAlert"
    >
      ✕
    </div>
    <p
      v-if="title"
      :class="[
        'text-left text-sm sm:text-xl lg:text-2xl',
        { 'text-center': singleMessage },
      ]"
      v-html="title"
    ></p>
    <div v-if="messages.length > 0" class="flex flex-col gap-y-6 sm:gap-y-10">
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="flex items-center gap-x-3 sm:gap-x-6 md:gap-x-8"
      >
        <img
          v-if="!singleMessage"
          src="@/assets/star.png"
          class="w-3 sm:w-6 md:w-8"
        />
        <p
          class="m-0 w-full text-sm sm:text-lg lg:text-xl"
          :class="{ 'text-center': singleMessage }"
          v-html="message"
        ></p>
      </div>
    </div>
    <div
      v-if="buttonText"
      class="flex flex-col items-center sm:text-2xl lg:text-3xl"
    >
      <base-button small data-cy="alertModalButton" @click="onButtonClick">
        {{ buttonText }}
      </base-button>
    </div>
    <slot />
  </div>
  <overlay v-if="alert || hasSlot" class="z-20" @click="closeAlert" />
</template>

<script lang="ts">
import Overlay from '@/components/Overlay.vue'
import useKeyboardInput from '@/use/useKeyboardInput'
import { Comment, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { key } from '@/store'

export default {
  components: { Overlay },
  inheritAttrs: false,

  props: {
    alert: {
      // { messages: string[], title: string, buttonText: string, buttonAction: function }
      type: Object,
      default: null,
    },
  },

  setup(props, { emit, slots }) {
    const store = useStore(key)
    const { t } = useI18n()
    const computedProps = {
      singleMessage: computed(() => {
        return !props.alert?.messages || props.alert?.messages.length === 1
      }),
      title: computed(() => {
        return props.alert?.title
      }),
      messages: computed(() => {
        return props.alert?.messages || []
      }),
      buttonText: computed(() => {
        return props.alert?.buttonText === undefined
          ? t('general.close')
          : props.alert?.buttonText
      }),
      hasSlot: computed(() => {
        return (
          slots.default &&
          slots.default().findIndex(o => o.type !== Comment) !== -1
        )
      }),
    }

    const closeAlert = () => {
      emit('close')
      store.dispatch('resetAlert')
    }

    const onButtonClick = () => {
      if (props.alert.buttonAction) props.alert.buttonAction()
      closeAlert()
    }

    const keyboardInput = e => {
      if (e.key === 'Escape') {
        closeAlert()
      }
    }

    useKeyboardInput(keyboardInput)

    return { ...computedProps, closeAlert, onButtonClick }
  },
}
</script>

<style>
.shadowStyles {
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.1);
}
</style>
