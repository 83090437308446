import { createStore, Store } from 'vuex'
import dispatchActionForAllModules from '@/utils/dispatchActionForAllModules'
import { store as modules } from './modules'
import getSavedState from '@/utils/getSavedState'
import saveStateToStorage from '@/utils/saveStateToStorage'
import { ProgressStep, State } from './modules/store'
import { InjectionKey } from 'vue'

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export default createStore<State>({
  state: {
    booking: null,
    locale: null,
    DOMLoaded: false,
    APILoading: false,
    alert: null,
    customAlert: null,
    checkinStarted: null,
    apiServer: getSavedState<string>('apiServer') || 'production',
    route: null,
    isPropertyDevice: getSavedState<boolean>('isPropertyDevice') ?? false,
  },
  getters: {
    DOMLoaded(state): boolean {
      return state.DOMLoaded
    },
    APILoading(state) {
      return state.APILoading
    },
    customAlert(state) {
      return state.customAlert
    },
    appLoading(state) {
      return !state.DOMLoaded || state.APILoading
    },
    apiServer(state) {
      return state.apiServer
    },
    checkinStarted(state) {
      return state.checkinStarted
    },
    progressStep(state) {
      return state.progressStep
    },
    themeColor() {
      return window?.document?.querySelector('meta[name="theme-color"]')
        ?.attributes['content']?.value
    },
    isPropertyDevice(state) {
      return state.isPropertyDevice
    },
  },
  mutations: {
    SET_DOM_LOADED(state) {
      state.DOMLoaded = true
    },
    SET_API_LOADING(state, value: boolean) {
      state.APILoading = value
    },
    SET_ALERT(state, alert) {
      state.alert = alert
    },
    SET_CUSTOM_ALERT(state, alert) {
      state.customAlert = alert
    },
    SET_CHECKIN_STARTED(state) {
      state.checkinStarted = new Date()
    },
    SET_API_SERVER(state, apiServer) {
      state.apiServer = apiServer
      saveStateToStorage<string>('apiServer', apiServer)
    },
    SET_ROUTE(state, route) {
      state.route = route
    },
    SET_THEME_COLOR(_, { color }) {
      window?.document
        ?.querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', color)
    },
    SET_PROPERTY_DEVICE(state, isPropertyDevice) {
      state.isPropertyDevice = isPropertyDevice
      saveStateToStorage<boolean>('isPropertyDevice', isPropertyDevice)
    },
  },
  actions: {
    setAPILoading({ commit }, value: boolean) {
      commit('SET_API_LOADING', value)
    },
    setDOMLoaded({ commit }) {
      commit('SET_DOM_LOADED')
    },
    setApiServer({ commit }, apiServer) {
      commit('SET_API_SERVER', apiServer)
    },
    setCheckinStarted({ commit }) {
      commit('SET_CHECKIN_STARTED')
    },
    setAlert(
      { commit },
      { title, messages, buttonText, buttonAction, classes }
    ) {
      commit('SET_ALERT', {
        title,
        messages,
        buttonText,
        buttonAction,
        classes,
      })
    },
    resetAlert({ commit }) {
      commit('SET_ALERT', null)
    },
    setCustomAlert({ commit }, { componentName, props }) {
      commit('SET_CUSTOM_ALERT', {
        componentName,
        props,
      })
    },
    resetCustomAlert({ commit }) {
      commit('SET_CUSTOM_ALERT', null)
    },
    async resetStore({ dispatch }) {
      await dispatch('auth/logOut')
      await dispatch('bookings/resetBooking')
      await dispatch('checkins/resetCheckin')
      await dispatch('minors/resetMinor')
    },
    setThemeColor({ commit }, { color }) {
      commit('SET_THEME_COLOR', {
        color,
      })
    },
    setPropertyDevice({ commit }, isPropertyDevice) {
      commit('SET_PROPERTY_DEVICE', isPropertyDevice)
    },
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: import.meta.env.NODE_ENV !== 'production',
})

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')
