import * as validators from '@vuelidate/validators'
import i18n from '@/i18n'
import { ValidationRuleWithParams } from '@vuelidate/core'

const { createI18nMessage } = validators
const { t } = i18n.global || i18n

const withI18nMessage = createI18nMessage({ t }) as (
  ...args: unknown[]
) => ValidationRuleWithParams

const isDate = value => value.match(/^\d{4}-\d{2}-\d{2}$/g)

export const required = withI18nMessage(validators.required)
export const email = withI18nMessage(validators.email)
export const date = withI18nMessage(isDate)
