<template>
  <div
    class="flex w-full justify-center"
    :class="{ 'font-notoSansSimplifiedChinese': locale === 'zh' }"
  >
    <div class="flex w-full md:max-w-[1080px]">
      <div
        class="absolute z-10 mx-auto flex h-full w-full max-w-[1080px] flex-col overflow-hidden overscroll-none bg-powder font-medium md:min-h-screen md:flex-grow"
      >
        <header-chooser @change="reportHeaderChange">
          <template #right>
            <!-- Any chosen Headers can include this on the right -->
            <div class="flex w-max flex-row items-center justify-between">
              <locale-switcher :transparent="true" class="mr-4" />
              <squishable-thingy><help-circle /></squishable-thingy>
            </div>
          </template>
        </header-chooser>
        <div
          class="top-0 flex h-full flex-col overflow-scroll px-4 pt-4 md:flex-grow md:px-16"
        >
          <router-view
            v-show="DOMLoaded"
            ref="routerPage"
            :class="{
              'pt-10': showingObasanHeader,
              'pb-0 md:pb-56': showingBackNextNav,
            }"
          />
          <back-next-nav @change="reportNavChange" />
        </div>
      </div>
      <transition>
        <base-spinner v-if="appLoading" class="translate-center fixed z-50" />
      </transition>
      <alert-modal v-if="alert" :alert="alert" />
      <custom-alerts v-if="customAlert" :alert="customAlert" />
      <debug-info
        class="fixed bottom-1/2 left-1/2 z-40 w-full -rotate-90 transform sm:bottom-0 sm:left-auto sm:h-20 sm:transform-none"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import AlertModal from '@/components/AlertModal.vue'
import BackNextNav from '@/components/BackNextNav.vue'
import CustomAlerts from '@/components/CustomAlerts.vue'
import HeaderChooser from '@/components/HeaderChooser.vue'
import DebugInfo from '@/components/DebugInfo.vue'
import { useStore } from 'vuex'
import { onMounted, computed, watch, ref } from 'vue'
import { key } from './store'
import { useRouter } from 'vue-router'
import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import HelpCircle from '@/components/HelpCircle.vue'
import SquishableThingy from '@/components/SquishableThingy.vue'
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()

const store = useStore(key)

const router = useRouter()
const routerPage = ref()

watch(router.currentRoute, () => {
  store.dispatch('wayfinder/reset')
  if (routerPage.value?.$el.nodeType !== 8) {
    // nodeType 8 is a commented element
    routerPage.value?.$el.scrollIntoView({ behavior: 'instant' })
  }
})

const alert = computed(() => store.state.alert)
const customAlert = computed(() => store.getters['customAlert'])
const DOMLoaded = computed(() => store.getters['DOMLoaded'])
const appLoading = computed(() => store.getters['appLoading'])

onMounted(() => {
  window.addEventListener('load', () => {
    setDOMLoaded()
  })
  setViewHeight()
  window.addEventListener('resize', setViewHeight)
})

const setDOMLoaded = () => store.dispatch('setDOMLoaded')
const setViewHeight = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const showingObasanHeader = ref(false)
const reportHeaderChange = ({ isShowingObasanHeader }) => {
  showingObasanHeader.value = isShowingObasanHeader
}

const showingBackNextNav = ref(false)
const reportNavChange = ({ isShowingBackNextNav }) => {
  showingBackNextNav.value = isShowingBackNextNav
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500;600;700&display=swap');
@import 'nprogress/nprogress.css';
@import './assets/tailwind.scss';

@media (hover: none) {
  *,
  *::before,
  *::after {
    /* Deactivate text selection on mobile */
    @apply select-none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
    -webkit-tap-highlight-color: transparent;
  }

  input,
  textarea {
    /* Allow text selection on inputs */
    @apply select-text;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ====== */
/* Vendor */
/* ====== */
#nprogress .bar {
  @apply bg-gray-800;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-option.is-selected.is-pointed {
  /* denim */
  background: #3c6997;
}

.multiselect-option.is-selected {
  /* denim */
  background: #3c6997;
}

.multiselect-option.is-pointed {
  /* peach */
  background: #ffd2c1;
}
</style>
