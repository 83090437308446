<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9999 36.6667C29.2047 36.6667 36.6666 29.2047 36.6666 20C36.6666 10.7953 29.2047 3.33333 19.9999 3.33333C10.7952 3.33333 3.33325 10.7953 3.33325 20C3.33325 29.2047 10.7952 36.6667 19.9999 36.6667Z"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.1499 15.0001C15.5417 13.8862 16.3152 12.9469 17.3332 12.3486C18.3512 11.7503 19.5481 11.5316 20.7119 11.7312C21.8757 11.9309 22.9313 12.5359 23.6917 13.4393C24.4521 14.3426 24.8683 15.4859 24.8666 16.6667C24.8666 20.0001 19.8666 21.6667 19.8666 21.6667"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 28.3333H20.0167"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
