import { logIn, LogInArguments, logOut } from '@/api/auth'
import saveStateToStorage from '@/utils/saveStateToStorage'
import getSavedState from '@/utils/getSavedState'
import { AuthProfile } from '@/types/api/types'

export const state = {
  currentUser: getSavedState('auth.currentUser'),
  headers: getSavedState('auth.headers'),
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveStateToStorage<AuthProfile>('auth.currentUser', newValue)
  },
  SET_AUTH_HEADERS(state, newValue) {
    state.headers = newValue
    saveStateToStorage<{ [key: string]: string }>('auth.headers', newValue)
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser && !!state.headers
  },
  currentUser(state) {
    return state.currentUser
  },
  headers(state) {
    return state.headers
  },
}

export const actions = {
  // This `init()` is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // init({ state, dispatch }) {},

  // Logs in the current user.
  logIn({ commit, dispatch }, { email, password }: LogInArguments = {}) {
    return logIn({ email, password }).then(response => {
      const user = response.data.data
      const headers = response.headers
      commit('SET_CURRENT_USER', user)
      commit('SET_AUTH_HEADERS', headers)
      dispatch('setPropertyDevice', true, { root: true })

      return user
    })
  },

  // Updates auth headers.
  updateHeaders({ commit }, headers) {
    commit('SET_AUTH_HEADERS', headers)
  },

  // Logs out the current user.
  async logOut({ getters, commit }) {
    if (getters.loggedIn) {
      try {
        await logOut()
      } finally {
        commit('SET_CURRENT_USER', null)
        commit('SET_AUTH_HEADERS', null)
        // clearLocalStorage()
        // router.go() // Refreshes the page with resets the in-memory store
      }
    }
  },
}
