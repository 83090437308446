import axios from 'axios'
import { config } from '@/constants'
import store from '@/store'
import errorHandler from '@/plugins/errorHandler'

// create an axios instance
const service = axios.create({
  baseURL: config.apiURL,
  timeout: 15000, // request timeout
})

// (Called 2nd) request interceptor
service.interceptors.request.use(
  serviceConfig => {
    const isAdmin = serviceConfig.url?.startsWith('/admin/')
    const apiVersion = isAdmin ? config.adminApiVersion : config.apiVersion
    serviceConfig.baseURL = `${serviceConfig.baseURL}/${apiVersion}`
    return serviceConfig
  },
  error => {
    Promise.reject(error)
  }
)

// (Called 1st) request interceptor
service.interceptors.request.use(
  serviceConfig => {
    store.dispatch('setAPILoading', true)

    const apiURL = config.apiServers[store.getters.apiServer]
    if (apiURL) serviceConfig.baseURL = apiURL

    const authHeaders = store.getters['auth/headers']
    serviceConfig.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...serviceConfig.headers,
      ...authHeaders,
    }

    return serviceConfig
  },
  error => {
    Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  async response => {
    if (response.status < 200 || response.status >= 300) console.warn(response)

    // Seems like cached requests don't generate new tokens.
    // Need to check first if a new access token has been provided.
    if (response.headers['access-token'])
      await store.dispatch('auth/updateHeaders', response.headers)
    store.dispatch('setAPILoading', false)
    return response
  },
  error => {
    store.dispatch('setAPILoading', false)
    return errorHandler.handle(error)
  }
)

export default service
