<template>
  <icon-help-circle @click="showHelp" />
</template>

<script setup lang="ts">
import IconHelpCircle from './icons/IconHelpCircle.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { key } from '@/store'
import { phoneNumberKey } from '@/use/usePropertyPhoneNumber'

const store = useStore(key)

const { t } = useI18n()

const showHelp = () => {
  store.dispatch('setAlert', {
    title: t('alerts.info.needHelp'),
    messages: [
      t('alerts.info.contactUs', { phoneNumber: t(phoneNumberKey.value) }),
    ],
  })
}
</script>
