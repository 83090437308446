import { Booking, Invite } from '@/types/api/types'
import Repository from './repository'

export type PostBookingInviteArgs = {
  bookingCode: string
  invite: Partial<Invite>
}

export type DeleteBookingInviteArgs = {
  bookingCode: string
  inviteUuid: string
}

export type GetBookingInviteArgs = {
  bookingCode: string
  inviteUuid: string
}

export default {
  getBooking(
    {
      bookingNo,
      propCode = null,
    }: {
      bookingNo: string | undefined
      propCode: string | null | undefined
    } = { bookingNo: undefined, propCode: undefined }
  ) {
    return Repository.get(`/bookings/${bookingNo}`, {
      // `params` key is required for GET query params
      params: { prop_code: propCode },
    })
  },
  resetMockBookings() {
    return Repository.get('/admin/bookings/reset_mocks')
  },
  checkout({ roomNumber, roomCode, propCode }) {
    return Repository.post(`/bookings/checkout`, {
      room_number: roomNumber,
      room_code: roomCode,
      prop_code: propCode,
    })
  },
  createInvite({ invite, bookingCode }: PostBookingInviteArgs) {
    return Repository.post<Booking>(`/bookings/${bookingCode}/invites`, {
      email: invite.email,
      firstName: invite.firstName,
      lastName: invite.lastName,
    })
  },
  getInvite({ inviteUuid, bookingCode }: GetBookingInviteArgs) {
    return Repository.get<Invite>(
      `/bookings/${bookingCode}/invites/${inviteUuid}`
    )
  },
  deleteInvite({ inviteUuid, bookingCode }: DeleteBookingInviteArgs) {
    return Repository.delete<Booking>(
      `/bookings/${bookingCode}/invites/${inviteUuid}`
    )
  },
  sendInviteEmails({ bookingCode }: { bookingCode: string }) {
    return Repository.post<Booking>(
      `/bookings/${bookingCode}/send_pre_checkin_invites`
    )
  },
}
