interface WayfinderConfig {
  action: Function
  label: string
  enabled: boolean
  secondary: boolean
  show: boolean
  listenOnKeypressEnter: boolean
}

interface Wayfinder {
  back: WayfinderConfig
  next: WayfinderConfig
}

const defaultNextWayfinderConfig = {
  action: () => {},
  label: 'general.next',
  enabled: true,
  show: true,
  listenOnKeypressEnter: true,
  secondary: false,
}

const defaultBackWayfinderConfig = {
  action: () => {},
  label: 'general.back',
  enabled: true,
  show: true,
  listenOnKeypressEnter: false,
  secondary: true,
}

export const state: Wayfinder = {
  back: defaultBackWayfinderConfig,
  next: defaultNextWayfinderConfig,
}

export const getters = {
  back(state: Wayfinder) {
    return state.back
  },
  next(state: Wayfinder) {
    return state.next
  },
}

export const mutations = {
  SET_BACK(state: Wayfinder, newConfig: Partial<WayfinderConfig>) {
    state.back = {
      ...state.back,
      ...newConfig,
    }
  },
  SET_NEXT(state: Wayfinder, newConfig: Partial<WayfinderConfig>) {
    state.next = {
      ...state.next,
      ...newConfig,
    }
  },

  RESET_BACK(state: Wayfinder) {
    state.back = defaultBackWayfinderConfig
  },
  RESET_NEXT(state: Wayfinder) {
    state.next = defaultNextWayfinderConfig
  },
}

export const actions = {
  updateBack({ commit }: { commit: Function }, back: Partial<WayfinderConfig>) {
    commit('SET_BACK', back)
  },
  updateNext({ commit }: { commit: Function }, next: Partial<WayfinderConfig>) {
    commit('SET_NEXT', next)
  },
  reset({ commit }: { commit: Function }) {
    commit('RESET_BACK')
    commit('RESET_NEXT')
  },
}
