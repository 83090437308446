<template>
  <base-label
    class="mb-4 w-full text-left text-sm font-semibold md:mb-6 md:text-base md:font-medium"
    :class="{ 'px-1': !oneLine }"
  >
    <span :class="{ 'inline-block w-1/3': oneLine }">
      {{ t('fields.dateOfBirth') }}
      <span v-if="showAsterisk" class="text-red-600">*</span>
    </span>
    <span v-if="!oneLine && !!v$.$errors.length" class="ml-2 text-red-600">
      {{ v$.$errors[0]?.$message }}
    </span>
    <div
      class="inline-grid grid-cols-3 gap-3 md:gap-2"
      :class="{ 'w-2/3': oneLine, 'w-full': !oneLine }"
    >
      <select
        name="month"
        :value="state.month"
        v-bind="$attrs"
        class="mt-2 inline-block h-[50px] bg-white text-sm font-normal focus:border-[1px] focus:border-black focus:outline-none md:mt-4 md:text-base md:font-semibold"
        :class="{
          'text-mediumgray': !state.month,
          'border-[1px] border-red-600 text-red-600':
            !!v$.month.$errors.length && v$.month.$dirty,
          'border-[1px] border-lightgray': borderAlwaysOn,
        }"
        data-cy="monthSelect"
        @input="handleDateChange($event.target, 'month')"
      >
        <option value>
          {{ t('fields.mm') }}
        </option>
        <option
          v-for="(value, key) in months"
          :key="key"
          :value="key"
          :selected="state.month === key.toString()"
        >
          {{ value }}
        </option>
      </select>
      <select
        name="day"
        :value="state.day"
        v-bind="$attrs"
        class="mt-2 inline-block h-[50px] bg-white text-sm font-normal focus:border-[1px] focus:border-black focus:outline-none md:mt-4 md:text-base md:font-semibold"
        :class="{
          'text-mediumgray': !state.day,
          'border-[1px] border-red-600 text-red-600':
            !!v$.day.$errors.length && v$.day.$dirty,
          'border-[1px] border-lightgray': borderAlwaysOn,
        }"
        data-cy="daySelect"
        @input="handleDateChange($event.target, 'day')"
      >
        <option value>
          {{ t('fields.dd') }}
        </option>
        <option
          v-for="(value, key) in days"
          :key="key"
          :value="key"
          :selected="state.day === key.toString()"
        >
          {{ value }}
        </option>
      </select>
      <select
        name="year"
        :value="state.year"
        v-bind="$attrs"
        class="mt-2 inline-block h-[50px] bg-white text-sm font-normal focus:border-[1px] focus:border-black focus:outline-none md:mt-4 md:text-base md:font-semibold"
        :class="{
          'text-mediumgray': !state.year,
          'border-[1px] border-red-600 text-red-600':
            !!v$.year.$errors.length && v$.year.$dirty,
          'border-[1px] border-lightgray': borderAlwaysOn,
        }"
        data-cy="yearSelect"
        @input="handleDateChange($event.target, 'year')"
      >
        <option value>
          {{ t('fields.yyyy') }}
        </option>
        <option
          v-for="(value, key) in yearsArray"
          :key="key"
          :value="value"
          :selected="state.year === value"
        >
          {{ value }}
        </option>
      </select>
      <span
        v-if="oneLine && !!v$.$errors.length"
        class="col-span-3 text-red-600"
      >
        {{ v$.$errors[0]?.$message }}
      </span>
    </div>
  </base-label>
</template>

<script setup lang="ts">
import { reactive, onMounted } from 'vue'
import scopedMsgs from '@/utils/i18nScopedMsgs'
import humanize from '@/utils/humanize'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { required } from '@/utils/i18nValidators.js'

type DateFields = {
  month: string
  day: string
  year: string
}

const props = withDefaults(
  defineProps<{
    modelValue: any
    yearsToGoBack?: number
    borderAlwaysOn?: boolean
    oneLine?: boolean
    showAsterisk?: boolean
  }>(),
  {
    yearsToGoBack: 99,
    borderAlwaysOn: false,
    oneLine: false,
    showAsterisk: false,
  }
)
const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>()
const state = reactive<DateFields>({ month: '', day: '', year: '' })
const t = scopedMsgs('pages.checkin.guestInfo')

onMounted(() => {
  if (props.modelValue && !state.month && !state.day && !state.year) {
    const birthDate = new Date(props.modelValue.replace(/-/g, '/'))
    state.month = (birthDate.getMonth() + 1).toString()
    state.day = birthDate.getDate().toString()
    state.year = birthDate.getFullYear().toString()
  }
})

const monthsArray = Array(12)
  .fill(1)
  .map((x, y) => (x + y).toString())
const months = Object.fromEntries(monthsArray.map(el => [el, humanize(el)]))
const daysArray = Array(31)
  .fill(1)
  .map((x, y) => (x + y).toString())
const days = Object.fromEntries(daysArray.map(el => [el, humanize(el)]))
const yearsArray = Array(props.yearsToGoBack + 1)
  .fill(new Date().getFullYear())
  .map((x, y) => humanize(Math.abs(x - y).toString()))

const rules: ValidationArgs = {
  month: { required },
  day: { required },
  year: { required },
}

const v$ = useVuelidate(rules, state)

const handleModelValue = () => {
  if (state.month && state.day && state.year) {
    emit('update:modelValue', `${state.year}-${state.month}-${state.day}`)
  }
}

const handleDateChange = (target: EventTarget | null, field: string) => {
  if (!target) return

  const value = (target as HTMLInputElement).value
  switch (field) {
    case 'month':
      state.month = value
      break
    case 'day':
      state.day = value
      break
    case 'year':
      state.year = value
      break
  }
  handleModelValue()
}
</script>
