<template>
  <div v-if="currentStep?.notFound != true">
    <div class="flex justify-between bg-peach px-4 pt-2 md:px-16 md:pt-10">
      <div class="flex items-center pb-[3%]">
        <slot name="left"></slot>
      </div>
      <div class="pb-[3%]">
        <slot name="right"></slot>
      </div>
    </div>
    <div class="relative min-h-[5rem] bg-peach">
      <!-- Left hand Exit button -->
      <squishable-thingy
        class="absolute bottom-0 left-0 origin-bottom-left"
        data-cy="exitFromHeader"
        @click="handleExitClick"
      >
        <div
          class="flex h-16 w-16 items-center justify-center rounded-tr-full bg-black text-sm font-semibold text-white"
        >
          <span class="-mb-4 -ml-4">{{ $t('general.exit') }}</span>
        </div>
      </squishable-thingy>
    </div>
    <!-- Sliding Progress Bar -->
    <div class="relative flex flex-row">
      <!-- "Reserved" Black buffer space at the left hand side -->
      <div
        class="`relative h-[6px] bg-black"
        :class="{ 'w-[15%]': !isMobile, 'w-[25%]': isMobile }"
      >
        <div class="absolute bg-black"></div>
      </div>
      <!-- The dynamic section -->
      <div
        class="relative h-[6px]"
        :class="{ 'w-[85%]': !isMobile, 'w-[75%]': isMobile }"
      >
        <!-- The Obasan -->
        <img
          ref="obasanEl"
          src="@/assets/illustrations/obasan_small.png"
          alt="Stylish Obasan"
          class="absolute -top-[4rem] h-16 w-16"
          :style="`margin-left: calc(${progressPerc}% - ${halfTheObasanWidth}px); transition: margin-left 1s;`"
        />
        <!-- The "animated" solid black line -->
        <div
          class="absolute z-10 h-[6px] bg-black"
          :style="`width: ${progressPerc}%; transition: width 1s;`"
        ></div>
        <!-- Static, dashed line -->
        <div class="dashedBackground absolute h-[6px] w-full"></div>
        <!-- The Text Label -->
        <div
          v-if="currentStep"
          ref="labelEl"
          data-cy="obasanLabelText"
          class="absolute top-4 z-50 min-w-max rounded-full bg-peach px-4 py-2 text-xs font-semibold"
          :style="`margin-left: calc(${progressPerc}% - ${halfTheLabelWidth}px); transition: margin-left 1s;`"
        >
          {{ $t(currentStep.messageKey) }}
        </div>
      </div>
      <!-- The Faded Background -->
      <div
        class="absolute top-[6px] z-40 h-16 w-full bg-gradient-to-b from-powder via-powder/95 to-transparent"
      ></div>
    </div>
    <return-to-reservation-confirmation-modal
      ref="exitModal"
    ></return-to-reservation-confirmation-modal>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import SquishableThingy from './SquishableThingy.vue'
import ReturnToReservationConfirmationModal from '@/components/modals/ReturnToReservationConfirmationModal.vue'
import isMobileDetector from '@/utils/isMobile'
import { CurrentStep } from '@/store/modules/checkins'

const isMobile = computed(isMobileDetector)

const exitModal =
  ref<InstanceType<typeof ReturnToReservationConfirmationModal>>()
const handleExitClick = () => {
  exitModal.value?.show()
}

const labelEl = ref<HTMLDivElement>()
const obasanEl = ref<HTMLDivElement>()

const props = defineProps<{ currentStep?: CurrentStep }>()

const progressPerc = computed(() =>
  Math.round(
    ((props.currentStep?.number || 0) /
      (props.currentStep?.totalStepCount || 0)) *
      100
  )
)

const currStepKey = computed(() => props.currentStep?.messageKey)
const halfTheLabelWidth = ref(0)
const halfTheObasanWidth = ref(0)

watch(
  currStepKey,
  () => {
    setTimeout(() => {
      halfTheLabelWidth.value =
        progressPerc.value === 100
          ? 0
          : Math.round((labelEl.value?.clientWidth || 0) / 2)
      halfTheObasanWidth.value =
        progressPerc.value === 100
          ? 0
          : Math.round((obasanEl.value?.clientWidth || 0) / 2)
    })
  },
  { immediate: true }
)
</script>

<style>
.dashedBackground {
  height: 6px;
  background-image: repeating-linear-gradient(
      0deg,
      black,
      black 20px,
      #f9f2ed 20px,
      #f9f2ed 40px,
      black 40px
    ),
    repeating-linear-gradient(
      90deg,
      black,
      black 20px,
      #f9f2ed 20px,
      #f9f2ed 40px,
      black 40px
    );
  background-size:
    6px 100%,
    100% 6px,
    6px 100%,
    100% 6px;
  background-position:
    0 0,
    0 0,
    100% 0,
    0 100%;
  background-repeat: no-repeat;
}
</style>
