import store from '@/store'
import router from '@/router'
import AlertModal from '@/components/AlertModal.vue'

const ErrorHandler = class {
  handle(e) {
    const error = e?.response?.data || e?.response || e

    if (e.toJSON().message == 'Network Error') {
      this.networkError()
    } else if (error.type === 'InvalidPropCode') {
      this.invalidPropCode()
    } else if (error.type === 'GuestNotFound') {
      // Error handled directly in the component
      // TODO: Refactor to move the alert here, but the AlertModal will need to be refactored because of the slot
    } else if (error.type === 'MustVerifyDateOfBirthToAssignGuest') {
      // Error handled directly
    } else if (error.type === 'IncorrectDateOfBirthWhenAssigningGuest') {
      // Error handled directly
    } else if (error.type === 'IncorrectDateOfBirthWhenReassigningGuest') {
      // Error handled directly
    } else if (store.getters['auth/loggedIn'] && e?.response?.status === 401) {
      this.unauthorizedError()
    } else if (error.error_code) {
      this.customError(error)
    } else if (error.message || error.messages) {
      this.defaultError(error)
    }

    return Promise.reject(error)
  }

  customError(error) {
    store.dispatch('setCustomAlert', {
      component: AlertModal,
      props: {
        errorCode: error.error_code,
        messages: error.messages ?? [error.message],
      },
    })
  }

  defaultError(error) {
    store.dispatch('setAlert', {
      title: 'Error',
      messages: error.messages ?? [error.message],
      buttonText: null,
    })
  }

  networkError() {
    store.dispatch('setAlert', {
      title: 'Seems like there are connection issues',
      messages: [
        'Check your internet connection.',
        'Make sure the Section L API is available.',
      ],
      buttonText: 'Refresh',
    })
  }

  unauthorizedError() {
    router.push({ name: 'Logout' })
  }

  invalidPropCode() {
    const property = store.getters['properties/property']
    store.dispatch('setAlert', {
      title: `Oops. Are you still at ${property.name}?`,
      messages: [
        'It seems your session has expired. We need to make sure you are still at the property.',
        `Please scan the QR code on the wall to confirm you are at ${property.name}.`,
      ],
      buttonText: 'Scan QR code',
      buttonAction: async () => {
        await store.dispatch('resetStore')
        router.push({ name: 'Scan', params: { resource: 'property' } })
      },
    })
    store.dispatch('properties/resetProperty')
  }
}

export default new ErrorHandler()
