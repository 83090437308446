import store from '@/store'
import { decode64 } from '@/utils/base64'

export async function storeMiddleware(to, from, next) {
  store.commit('SET_ROUTE', to)
  if (!to.query.q) return

  const url = new URL(decode64(to.query.q), window.location.origin)
  const queryParams = new URLSearchParams(url.search)

  // Extract data from query params
  const resetProperty = queryParams.get('rp')
  queryParams.delete('rp')

  const propertyCode = queryParams.get('propCode')
  queryParams.delete('propCode')

  const bookingNo = queryParams.get('b')
  queryParams.delete('b')

  const [email, primary] = [queryParams.get('e'), queryParams.get('p') === '1']
  queryParams.delete('p')
  queryParams.delete('e')

  // Build new query Object from remaining query params
  const query = Object.fromEntries([...queryParams.entries()])

  // Dispatch actions and fetch records
  if (resetProperty) await store.dispatch('properties/resetProperty')

  if (propertyCode) {
    await store.dispatch('properties/fetchProperty', {
      passCode: propertyCode,
    })
  }

  if (bookingNo) {
    await store.dispatch('bookings/fetchBooking', {
      bookingNo,
    })
  }

  // Redirect
  if (
    store.getters['properties/atProperty'] &&
    store.getters['bookings/booking']
  ) {
    next({
      name: 'ReservationConfirmation',
      query,
    })
  } else if (url.pathname === '/checkin/invited') {
    const invite = await store.dispatch('bookings/fetchInvite', {
      bookingCode: bookingNo,
      inviteUuid: queryParams.get('uuid'),
    })
    if (invite) {
      next({ name: 'Email', query: { ...query, email: invite.email } })
    } else {
      next({ name: 'Home' })
    }
  } else {
    next({ path: url.pathname, query })
  }
  throw 'Redirected'
}
