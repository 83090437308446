import { createI18n, I18nOptions } from 'vue-i18n'
import { config } from '@/constants'
import en from '@/locales/en.json'
import ja from '@/locales/ja.json'
import zh from '@/locales/zh.json'

const datetimeFormats: I18nOptions['datetimeFormats'] = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    booking: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'Asia/Tokyo',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  ja: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    booking: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'Asia/Tokyo',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
  zh: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    booking: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'Asia/Tokyo',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    },
  },
}

export type MessageSchema = typeof en

export default createI18n({
  legacy: false,
  locale: config.defaultLocale || 'en',
  fallbackLocale: config.defaultLocale || 'en',
  messages: { en, ja, zh },
  datetimeFormats,
})
